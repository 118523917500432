import React, { useEffect } from 'react'
function Carousel({ yantras }) {
  useEffect(() => {
    // Call the function defined in the script tag
    if (typeof window.StartCurosel === 'function') {
      const result = window.StartCurosel();
    } else {
      console.error('StartCurosel function is not defined');
    }
  }, []);
  return (
    <>
      <div className="carousel_wrapper">
        <div id="demo" className="carousel slide card" data-bs-ride="carousel">
          {yantras.length > 0 &&
            <div className="carousel-inner">
              {yantras.map((yantra, index) => (
                <div key={index} className={(yantra.id === 1) ? "carousel-item active" : "carousel-item"}>
                  <img src={'assets/theme/images/' + yantra.name + '.png'} alt="" className="d-block" />
                  <h2>{yantra.hindi}</h2>
                </div>
              ))}
            </div>
          }
        </div>
      </div>
    </>
  )
}

export default Carousel