// AuthContext.js
import React, { createContext, useEffect, useState, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { enCreption, decryptData } from './common/Aes';
import Api from './common/Api';
const AuthContext = createContext();


export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [authData, setAuthData] = useState(localStorage.getItem('auth'));

  const refreshToken = async() => {
    if (!token) return
    try {
      const response = await Api.post("/refresh",{}, {
        headers: {'Authorization': "Bearer " + token}
      })
    }
    catch(error){
      logout();
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      refreshToken();
    }, 480000); // Refresh token every 8 minutes (adjust as needed)
    // Clean up event listeners
    return () => {
      clearInterval(intervalId);
    };
  }, [refreshToken]);

  const login = async (token, data) => {
    setToken(token);
    setAuthData(data);
    localStorage.setItem('token', token);
    localStorage.setItem('auth', data);
  };

  const logout = async () => {
    const auth_user = decryptData(authData);
    var payload = await enCreption(auth_user);
      await Api.post(`logout`, payload)
        .then(res => {
          const response = res.data;
          //const msg = response.msg;
          if (response.status === "success") {
            setToken(null);
            setAuthData(null);
            localStorage.removeItem('token');
            localStorage.removeItem('auth');
            //toast.success(msg, ToastObj);
            //console.log(msg);
            <Navigate to="/" />
          } else if (response.status === "already") {
            <Navigate to="/" />
          } else {
            console.log("Something went wrong !!!");
          }
        });
  };

  return (
    <AuthContext.Provider value={{ token, authData, login, logout, refreshToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};