import React, { useEffect, useState } from 'react'
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import ToastObj from '../common/ToastObj';
import 'react-toastify/dist/ReactToastify.css';
import { omit } from 'lodash'
import Api from "../common/Api"
import { enCreption, encryptData } from '../common/Aes';

export default function Login() {
  const { token, login } = useAuth();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  let formErrors = {};

  useEffect(() => {
    document.body.style.backgroundColor = '#ffffff';
    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []);

  useEffect(() => {
    if (token)
      navigate('/five-game');
  }, [token]);

  const validations = (username, password) => {
    if (username.length === 0) {
      formErrors.username = "Please enter username.";
    } else {
      let newObj = omit(formErrors, "username");
      formErrors = newObj;
    }

    if (password.length === 0) {
      formErrors.password = "Please enter password.";
    } else {
      let newObj = omit(formErrors, "password");
      formErrors = newObj;
    }

    setErrors(formErrors);
  };

  const handleSubmit = async (event) => {
    try{
    event.preventDefault();
    event.persist();
    let username = event.target.username.value;
    let password = event.target.password.value;
    validations(username, password);
    if (Object.keys(formErrors).length === 0) {
      setLoader(true);
      var payload = await enCreption({ username: username, password: password });
      await Api.post(`authorization`, payload)
        .then(res => {
          setLoader(false);
          const response = res.data;
          const msg = response.msg;
          if (response.status === "success") {
            toast.success(msg, ToastObj);
            setTimeout(function () {
              login(response.token, encryptData(response.data));
              navigate('/five-game');
            }, 2000);
          } else if (response.status === "denied") {
            toast.error(msg, ToastObj);
          } else if (response.status === "error") {
            toast.error(msg, ToastObj);
          } else {
            toast.error("Something went wrong !!!", ToastObj);
          }
        });
    }
  } catch (error) {
    toast.error("Something went wrong !!!", ToastObj);
  }
  }

  return (
    <>
      <ToastContainer />
      <div className="bg-light py-3 py-md-5 d-flex align-items-center" style={{ minHeight: "100vh" }}>
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12 col-md-11 col-lg-8 col-xl-7 col-xxl-6">
              <div className="bg-white p-4 p-md-5 rounded shadow-sm">
                <div className="row">
                  <div className="col-12">
                    <div className="text-center mb-5">
                      <a href="#!">
                        <img src="galaxy-logo.png" alt="BootstrapBrain Logo" style={{ maxWidth: "100%" }} />
                      </a>
                    </div>
                  </div>
                </div>
                <form onSubmit={handleSubmit} action="#!">
                  <div className="row gy-3 gy-md-4 overflow-hidden">
                    <div className="col-12">
                      <label htmlFor="username" className="form-label">Username <span className="text-danger">*</span></label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone" viewBox="0 0 16 16">
                            <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                          </svg>
                        </span>
                        <input type="text" className="form-control" name="username" id="username" />
                      </div>
                      {errors.username && <small className='error' style={{ fontSize: "12px", color: "red" }}>{errors.username}</small>}
                    </div>
                    <div className="col-12">
                      <label htmlFor="password" className="form-label">Password <span className="text-danger">*</span></label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-key" viewBox="0 0 16 16">
                            <path d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8zm4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5z" />
                          </svg>
                        </span>
                        <input type="password" className="form-control" name="password" id="password" />
                      </div>
                      {errors.password && <small className='error' style={{ fontSize: "12px", color: "red" }}>{errors.password}</small>}
                    </div>
                    <div className="col-12">
                      <div className="d-grid">
                        <button className="btn btn-primary btn-lg" type="submit">Log In &nbsp; {(loader) ? <i className="fa fa-circle-o-notch fa-spin"></i> : <span>&nbsp;</span>}</button>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="row">
                  <div className="col-12">
                    <hr className="mt-5 mb-4 border-secondary-subtle" />
                    <div className="d-flex gap-2 gap-md-4 flex-column flex-md-row justify-content-md-center">
                      By using this app, you agree to our terms of service and privacy policy.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
