import AES from 'crypto-js/aes';
import CryptoJS from 'crypto-js';
import { Buffer } from 'buffer';

const key = process.env.REACT_APP_CRYPTOJS_PASSKEY;
const iv = process.env.REACT_APP_CRYPTOJS_IV; // 16 bytes IV in Uint8Array for AES-CBC mode

export const enCreption = async (data) => {
  let msg = JSON.stringify(data);
  var converter = AES.encrypt(msg, key, {name: 'AES-GCM', iv : iv}).toString();
  var payload = Buffer.from(converter).toString('base64');
  return payload;
}

export const encryptData = (data) => {
  let msg = JSON.stringify(data);
  return CryptoJS.AES.encrypt(msg, key).toString();
};

export const decryptData = (ciphertext) => {
  try {
    const bytes = CryptoJS.AES.decrypt(ciphertext, key);
    var data = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(data);
  } catch (e) {
    return "error";
  }
};

/* aes.decrypt(data, key, {name: 'AES-CBC', iv}).then( (decrypted) => {
  // now you get an Uint8Array of decrypted message
}); */