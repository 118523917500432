import React, { useEffect, useState, useRef, useCallback, Suspense } from 'react'
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import '../assets/css/style.css';
import Api from '../common/Api';
import { enCreption, decryptData } from '../common/Aes';
import { ToastContainer, toast } from 'react-toastify';
import ToastObj from '../common/ToastObj';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'
import Carousel from './Carousel';
import Sparkel from '../componants/Sparkel';
import Modal from '../componants/Modal';
import Clock from '../componants/Clock';
import JsBarcode from 'jsbarcode';
const LastGames = React.lazy(() => import("./LastGames"));

export default function FiveGame() {
  const { authData, logout } = useAuth();
  const navigate = useNavigate();
  const auth_user = decryptData(authData);
  const game = 10; //set game min
  const [yantras, setYantras] = useState({});
  const [wallet, setWallet] = useState({});
  const [currentGame, setCurrentGame] = useState({});
  const [updateCurrentGame, setUpdateCurrentGame] = useState(true);
  const [freeze, setFreeze] = useState(false);
  const [formData, setFormData] = useState({});
  const [receiptAmount, setReceiptAmount] = useState(0);
  const [afterBalance, setAfterBalance] = useState(0);
  const [checkJackpot, setCheckJackpot] = useState(true);
  const [allDraws, setAllDraws] = useState([]);
  const saveRef = useRef(null);
  const reprintRef = useRef(null);
  const claimRef = useRef(null);
  const cancelRef = useRef(null);
  const iframeRef = useRef(null);
  const [receipt, setReceipt] = useState(null);
  const [rePrintReceipt, setRePrintReceipt] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [barcode, setBarcode] = useState('');
  const [winner, setWinner] = useState(null);
  const [jackpot, setJackpot] = useState(false);
  const [confetti, setConffetti] = useState(false);
  let scanValue = '';
  let audio = null;

  const updateWallet = async () => {
    const payload = await enCreption({ vendor_id: auth_user.id });
    //console.log(payload);
    await Api.post(`get_wallet`, payload)
      .then(res => {
        const response = res.data;
        if (response.status === "success") {
          setWallet(response.data);
        } else if (response.status === "error") {
          toast.error(response.msg, ToastObj);
        } else {
          toast.error("Something went wrong !!!", ToastObj);
        }
      });
  };

  const getYantras = async () => {
    //console.log(payload);
    await Api.get(`get_yantras`)
      .then(res => {
        const response = res.data;
        if (response.status === "success") {
          setYantras(response.data);
        } else if (response.status === "error") {
          toast.error(response.msg, ToastObj);
        } else {
          toast.error("Something went wrong !!!", ToastObj);
        }
      });
  };

  const getAllDraws = async () => {
    const payload = await enCreption({ game_type: game });
    //console.log(payload);
    try {
      await Api.post(`get_draws`, payload)
        .then(res => {
          const response = res.data;
          if (response.status === "success") {
            setAllDraws(response.draws);
          } else if (response.status === "error") {
            toast.error(response.msg, ToastObj);
          } else {
            window.location.reload();
          }
        });
    } catch (error) {
      window.location.reload();
    }
  };

  const focusElementById = (id) => {
    const element = document.getElementById(id);
    if (element !== null) {
      element.focus();
    }
  };

  useEffect(() => {
    getYantras();
    updateWallet();
    getAllDraws();
    if (game === 10) {
      document.body.style.backgroundColor = '#009000';
      return () => {
        document.body.style.backgroundColor = ''; // Reset to default
      };
    }
  }, []);

  useEffect(() => {
    if (yantras)
      focusElementById('yantra-0');
  }, [yantras]);

  const handleGlobalKeyDown = useCallback((event) => {
    // Handle global key events
    if (event.key === 'F4') {
      event.preventDefault();
      handleClear(event);
    } else if (event.key === 'F3') {
        event.preventDefault();
        cancelRef.current.click();
    } else if (event.key === 'F2') {
      event.preventDefault();
      handleClaims(event);
    } else if (event.key === 'p' || event.key === 'P') {
      event.preventDefault();
      reprintRef.current.click();
    } else if (event.keyCode === 16) {
      return;
    } else if (event.key === 'Enter' || event.keyCode === 13) {
      event.preventDefault();
      if (scanValue) {
        //console.log("YAHAPE ==> " + scanValue);
        setBarcode(scanValue);
        setFormData({});
        setReceiptAmount(0)
        setAfterBalance(0);
        return;
      } else {
        saveRef.current.click();
      }
    }
    scanValue += event.key;
    setTimeout(() => {
      scanValue = '';
    }, 100)
  }, []);

  const handleKeyDown = (event, index = 0) => {
    if (event.key === 'ArrowRight' && index < 9) {
      event.preventDefault();
      const nextElement = document.getElementById(`yantra-${index + 1}`);
      nextElement.focus();
    }
    if (event.key === 'ArrowLeft' && index > 0) {
      event.preventDefault();
      const prevElement = document.getElementById(`yantra-${index - 1}`);
      prevElement.focus();
    }

    if (event.key === 'ArrowUp' && index !== null) {
      event.preventDefault();
      const upElement = document.getElementById(`yantra-${(index - 5 + 10) % 10}`);
      upElement.focus();
    }

    if (event.key === 'ArrowDown' && index !== null) {
      event.preventDefault();
      const downElement = document.getElementById(`yantra-${(index + 5) % 10}`);
      downElement.focus();
    }

    // if (event.key === 'F4' || event.keyCode === 115) {
    //   event.preventDefault();
    //   handleClear(event)
    //   return;
    // }
    // if (event.key === 'p' || event.key === 'P' || event.keyCode === 80) {
    //   event.preventDefault();
    //   handleReprint(event);
    //   return;
    // }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleGlobalKeyDown);
    return () => {
      document.removeEventListener('keydown', handleGlobalKeyDown);
    };
  }, []);

  const setGame = async (gameNow) => {
    try {
      // Update current game state
      //const payload = await enCreption({ vendor_id: auth_user.id, game: data });
      //const res = await Api.post('games', payload);
      //const response = res.data;
      if (gameNow && typeof gameNow === 'object' && Object.keys(gameNow).length > 0) {
        setCurrentGame(gameNow);
        setUpdateCurrentGame(false);
        setCheckJackpot(true);// Example return value; adjust as needed
      } else {
        setUpdateCurrentGame(true)
        setCheckJackpot(false);
      }
    } catch (error) {
      toast.error('Something went wrong !!!', ToastObj);
      setTimeout(() => {
        window.location.reload();
      },3000)
      //console.error('Error Fetching game:', error);
      //window.location.reload();
      // toast.error('Failed to create game. Please try again later.', ToastObj); // Generic error message
    }
  };

  const removeKey = (keyToRemove) => {
    // Check if the key exists in the object
    if (formData.hasOwnProperty(keyToRemove)) {
      // Create a new object with all keys except the one to remove
      const newObj = { ...formData };
      delete newObj[keyToRemove];
      // Update state with the new object
      setFormData(newObj);
    }
  };

  useEffect(()=>{
   if (formData && typeof formData === 'object' && Object.keys(formData).length > 0) {
    const sum = Object.values(formData).reduce((acc, value) => acc + Number(value), 0);
    // Multiply the sum by 11
    const result = sum * 11;
    if (parseInt(wallet.amount) <  sum ) {
      Swal.fire({
        title: 'Error!',
        text: 'Insufficient wallet balance to proceed !!!',
        icon: 'error',
        confirmButtonText: 'Okay',
        allowOutsideClick: false
      })
      setFormData({});
      setReceiptAmount(0)
      setAfterBalance(0);
      focusElementById('yantra-0');
    } else {
      setReceiptAmount(result);
      setAfterBalance(parseInt(wallet.amount) - result);
    }
   } else {
      setReceiptAmount(0)
      setAfterBalance(0);
   }
  },[formData]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (value !== '' && scanValue === '' && (isNaN(value) || value <= 0)) {
      Swal.fire({
        title: 'Error!',
        text: 'Please enter a number between 1 and 100',
        icon: 'error',
        confirmButtonText: 'Okay'
      })
      event.target.value = '';
      removeKey(name);
      return;
    } else if (value !== null && value !== "" && scanValue === '') {
      const cost = parseInt(value) * 11;
      if ((parseInt(wallet.amount) - receiptAmount) > cost) {
        //var currentAmount = receiptAmount + cost;
        setFormData({ ...formData, [name]: value });
      }
    } else if (value === null || value === "") {
      removeKey(name);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (Object.keys(formData).length > 0) {
        setLoading(true);
        const payload = await enCreption({ vendor_id: auth_user.id, game: currentGame, formData: formData });
        await Api.post(`bidding`, payload)
          .then(res => {
            const response = res.data;
            if (response.status === "success") {
              focusElementById('yantra-0');
              setReceipt(response.receipt);
              setRePrintReceipt(response.receipt);
              setFormData({});
              updateWallet();
              setReceiptAmount(0)
              setAfterBalance(0);
              setLoading(false);
            } else if (response.status === "error") {
              setLoading(false);
              toast.error(response.msg, ToastObj);
            } else if (response.status === "mismatch") {
              setLoading(false);
              Swal.fire({
                title: 'Error!',
                text: response.msg,
                icon: 'error',
                confirmButtonText: 'Okay'
              })
            } else if (response.status == 'insufficient') {
              Swal.fire({
                title: 'Error!',
                text: 'Insufficient wallet balance to proceed !!!',
                icon: 'error',
                confirmButtonText: 'Okay',
                allowOutsideClick: false
              })
              setFormData({});
              setReceiptAmount(0)
              setAfterBalance(0);
              focusElementById('yantra-0');
            } else {
              setLoading(false);
              toast.error("Something went wrong !!!", ToastObj);
            }
          });
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Please enter a qantity between 1 and 100',
          icon: 'error',
          confirmButtonText: 'Okay'
        })
      }
    } catch (error) {
      console.error('Error creating game:', error);
      toast.error("Something went wrong !!!", ToastObj);
    }
  };

  const handleCancel = async (event) => {
    event.preventDefault();
    try {
      if (rePrintReceipt && Object.keys(rePrintReceipt).length > 0) {
        setCancelLoading(true);
        const payload = await enCreption({ vendor_id: auth_user.id, receipt: rePrintReceipt });
        await Api.post(`cancelReceipt`, payload)
          .then(res => {
            const response = res.data;
            if (response.status === "success") {
              focusElementById('yantra-0');
              setReceipt(null);
              setRePrintReceipt(null);
              updateWallet();
              setCancelLoading(false);
              Swal.fire({
                title: response.title,
                html: response.msg,
                icon: 'success',
                confirmButtonText: 'Okay'
              })
            } else if (response.status === "error") {
              setCancelLoading(false);
              toast.error(response.msg, ToastObj);
            } else {
              setCancelLoading(false);
              toast.error("Something went wrong !!!", ToastObj);
            }
          });
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'No recent receipts were found for cancellations.',
          icon: 'error',
          confirmButtonText: 'Okay'
        })
      }
    } catch (error) {
      console.error('Error creating game:', error);
      toast.error("Something went wrong !!!", ToastObj);
    }
  }

  const handleClear = (event) => {
    event.preventDefault();
    focusElementById('yantra-0');
    setFormData({});
  }

  const handleReprint = (event) => {
    event.preventDefault();
    focusElementById('yantra-0');
    setTimeout(() => { setReceipt(rePrintReceipt); }, 100);
  }

  const generateBarcodeDataUrl = (value) => {
    const canvas = document.createElement('canvas');
    JsBarcode(canvas, value, { format: 'CODE128', displayValue: false, width: 2, height: 40 });
    return canvas.toDataURL('image/png');
  };

  const generateReceiptContent = (data) => {
    if (!data) return '';
    const barcodeUrl = generateBarcodeDataUrl(data.bid_id);
    const itemsHtml = data.items.map(item => `
    <tr>
      <td>${item.yantra_id}</td>
      <td>${item.hindi}</td>
      <td>${item.quantity}</td>
      <td>x ${item.rate}</td>
      <td>${item.amount}</td>
    </tr>
    `).join('');

    return `
      <html>
        <head>
          <style>
            body { font-family: Arial, sans-serif; font-size: 14px; width: 100%; margin:0 auto; padding:0}
            h1, h2, h3, h4 { text-align: center;}
            table { width: 100%; }
            th, td { padding: 3px; text-align: left; }
            .custom-table {
              border: 1px solid gray;
              border-collapse: collapse;
            }
            .custom-table td,
            .custom-table th,
            .custom-table tr {
                border: 1px solid gray;
            }
            @media print {
              @page { margin: 0; }
              body { margin:  0; padding: 0; border: 0;}
              table { margin: 0; padding: 0; border-spacing: 0; border-collapse: collapse;}
            }          
          </style>
        </head>
        <body>
          <h2>GALAXY</h2>
          <table>
        <tr>
          <th>Draw Id (${data.type}) </th>
          <td>:</td>
          <td>${data.gameId}</td>
      </tr>
      <tr>
          <th>Purchase Date</th>
          <td>:</td>
          <td>${data.purchase_date}</td>
      </tr>
      <tr>
          <th>Purchase Time</th>
          <td>:</td>
          <td>${data.purchase_time}</td>
      </tr>
      </tr>
          <th>Draw Time</th>
          <td>:</td>
          <td>${data.draw_time}</td>
      </tr>
          </table>
          <table class="custom-table">
            <thead>
              <tr><td colspan="5"><h3>Receipt No. : ${data.receipt_no} </h3></td></tr>
              <tr>
                <th>No.</th>
                <th>Yantra</th>
                <th>Qty</th>
                <th>Rate</th>
                <th>Totals</th>
              </tr>
            </thead>
            <tbody>
              ${itemsHtml}
              <tr>
              <td>&nbsp;</td>
              <th>Total</th>
              <th>${data.total_qty}</th>
              <td>&nbsp;</td>
              <th>${data.total}</th>
              </tr>
              <tr>
                <td colspan=5>
                  <div style="text-align:center">
                    <img src="${barcodeUrl}" alt="Barcode" height="50px"/>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </body>
      </html>
    `;
  };

  const handlePrint = () => {
    if (iframeRef.current && iframeRef.current.contentWindow) {
      iframeRef.current.contentWindow.focus();
      iframeRef.current.contentWindow.print();
      iframeRef.current.contentWindow.close();
      setReceipt(null);
      setWinner(null);
      setBarcode('');
    }
  };

  useEffect(() => {
    if (receipt || winner) {
      // Delay the print to ensure iframe is rendered
      const timer = setTimeout(() => handlePrint(), 500);
      return () => clearTimeout(timer);
    }
  }, [receipt, winner]);

  useEffect(() => {
    if (barcode) {
      const timer = setTimeout(() => handleWinner(), 1000);
      return () => clearTimeout(timer);
    }
  }, [barcode]);

  const playAudio = (audio) => {
    if (audio) {
      audio.play().catch((error) => {
        console.error('Playback error:', error);
      });
    }
  };

  useEffect(() => {
    if (jackpot) {
      const timer = setTimeout(() => {
        audio = new Audio('assets/sounds/you-won.mp3');
        if (document.hasFocus())
          playAudio(audio);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [jackpot]);

  const getJackpot = async (currentGame) => {
    try {
      // Update current game state
      const payload = await enCreption({ vendor_id: auth_user.id, game: currentGame });
      const res = await Api.post('checkJackpot', payload);
      const response = res.data;
      if (response.status === 'success') {
        setCheckJackpot(false);// Example return value; adjust as needed
        setJackpot(response.jackpot);
      } else {
        toast.error('Something went wrong !!!', ToastObj);
        //window.location.reload();
      }
    } catch (error) {
      console.error('Error creating game:', error);
      //window.location.reload();
      toast.error('Failed to make draw. Please refresh page !!!.', ToastObj); // Generic error message
    }
  };

  const myDraws = async (currentGame) => {
    try {
      // Update current game state
      const payload = await enCreption({ game: currentGame });
      const res = await Api.post('mydraws', payload);
      const response = res.data;
      var sec = 10000;
      if (response.status === 'success') {
        if (jackpot) {
          sec = 20000;
          setConffetti(true);
        } else {
          audio = new Audio('assets/sounds/stunner.mp3'); // Replace with your audio file path
          if (document.hasFocus())
            playAudio(audio);
        }
        setShowModal(true);
        setModalData(response.draw);
        setAllDraws([...allDraws, response.draw]);
        setTimeout(() => {
          if (audio)
            audio.pause();
          setConffetti(false);
          setShowModal(false);
          setModalData({});
          focusElementById('yantra-0');
        }, sec);
      } else if (response.status == 'mismatch') {
        Swal.fire({
          title: 'Error!',
          text: response.msg,
          icon: 'error',
          confirmButtonText: 'Okay'
        })
      } else {
        toast.error('Something went wrong !!!', ToastObj);
        setTimeout(() => {
          window.location.reload();
        },3000);
      }
    } catch (error) {
      console.error('Error creating game:', error);
      window.location.reload();
    }
  };

  const handleClaims = async (event) => {
    event.preventDefault();
    await Swal.fire({
      position: 'center-middle',
      title: "Receipt Withdrawal",
      input: "text",
      showCancelButton: true,
      confirmButtonText: "Claim",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        var claimReceipt = document.getElementById("swal2-input").value;
        const claimRec = claimReceipt.trim().split(/\s+/);
        if (claimRec != "" && claimRec != null) {
          try {
            var payload = await enCreption({ vendor_id: auth_user.id, receipt_no: claimRec });
            await Api.post(`claims`, payload)
              .then(res => {
                var response = res.data;
                setWinner(response);
                if (response.status == "success") {
                  updateWallet();
                }
              });
            return true;
          } catch (error) {
            Swal.showValidationMessage(`Request failed: ${error}`);
          }
        } else if (claimRec == "" || claimRec == null) {
          return Swal.showValidationMessage("Please enter Receipt No.");
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    });
  }

  const handleWinner = async () => {
    const claimRec = barcode.trim().split(/\s+/);
    if (claimRec != "" && claimRec != null) {
      try {
        var payload = await enCreption({ vendor_id: auth_user.id, receipt_no: claimRec });
        await Api.post(`claims`, payload)
          .then(res => {
            var response = res.data;
            var status = response.status;
            setWinner(response);
            if (status == "success") {
              updateWallet();
            }
          });
        return true;
      } catch (error) {
        Swal.showValidationMessage(`Request failed: ${error}`);
      }
    }
  }

  const generateWinnerContent = (data) => {
    if (!data) return '';
    return `
      <html>
        <head>
          <style>
            body { font-family: Arial, sans-serif; font-size: 14px; width: 100%; margin:0; padding:0}
            h1, h2, h3, h4 { text-align: center;}
            table { width: 100%; }
            th, td { padding: 3px; text-align: left; }
            .custom-table {
              border: 1px solid gray;
              border-collapse: collapse;
            }
            .custom-table td,
            .custom-table th,
            .custom-table tr {
                border: 1px solid gray;
            }
            @media print {
              @page { margin: 0; }
              body  { margin:  0; padding: 0; border: 0;}
              table { margin: 0; padding: 0; border-spacing: 0; border-collapse: collapse;}
            }          
          </style>
        </head>
        <body>
          <h2>GALAXY</h2>
          <h3>`+ data.title + `</h3>
          <h3>`+ data.msg + `</h3>
        </body>
      </html>
    `;
  };

  return (
    <>
      <ToastContainer />
      {confetti && <Sparkel />}
      {(receipt || winner) && (
        <iframe
          ref={iframeRef}
          srcDoc={(receipt) ? generateReceiptContent(receipt) : (winner) ? generateWinnerContent(winner) : ''}
          style={{ display: 'none' }}
          title="Print Receipt"
        />
      )}
      <main>
        <nav className="sticky-top custom_nav navbar navbar-expand-sm">
          <div className="container-fluid">
            <a className="navbar-brand"><img src="assets/theme/images/galaxy-logo.png" className="img-fluid" alt='logo' /></a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="d-flex right_text">
              <div><h4>{auth_user.vendor_id}</h4></div>
              <div><h4>{wallet.amount}</h4></div>
            </div>
          </div>
        </nav>
        <section>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-xl-12 col-xxl-12">
                <div className="date_time">
                  <Clock gameType={game} setGame={setGame} currentGame={currentGame} updateCurrentGame={updateCurrentGame} setUpdateCurrentGame={setUpdateCurrentGame} freezeInput={setFreeze} checkJackpot={checkJackpot} getJackpot={getJackpot} myDraws={myDraws} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-xl-4 col-xxl-4">
                {/* { Carousel } */}
                <Carousel yantras={yantras} />
              </div>
              <div className="col-lg-8 col-xl-8 col-xxl-8 images_wrapper">
                {yantras.length > 0 &&
                  <div className="row">
                    {yantras.map((yantra, index) => (
                      <div key={index} className="col">
                        <div className="card image_card">
                          <div className="card-header text-center"><h5 className="mb-0">{yantra.hindi}</h5></div>
                          <div className="card-body mx-auto"><img src={"assets/theme/images/" + yantra.name + ".png"} className="responsive img-fluid" alt={yantra.name} /></div>
                          <div className="card-footer"><input type="text" inputMode="numeric" min={1} className="form-control custom_input text-center" id={`yantra-${index}`} name={yantra.name + "_" + yantra.id} value={formData[yantra.name + "_" + yantra.id] || ''} onChange={handleChange} onKeyDown={(event) => handleKeyDown(event, index)} disabled={freeze} autoComplete='off' /></div>
                        </div>
                      </div>
                    ))}
                  </div>
                }
              </div>
            </div>
          </div>
        </section>
        <section>
          <Suspense fallback={<p className='text-center'>Last Draws is Loadding...</p>}>
            <LastGames drawData={allDraws} />
          </Suspense>
        </section>
        <section className="footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-4">
                <div className="action_buttons text-sm-start flex_actions start">
                  <button type="button" ref={reprintRef} className="btn" onClick={handleReprint}>Re-Print [P]</button>
                  <button type="button" ref={cancelRef} className="btn" onClick={handleCancel}>Cancel [F3] {(cancelLoading) && <i className="fa fa-circle-o-notch fa-spin"></i>}</button>
                  <button type="button" className="btn" onClick={handleClear} >Clear [F4]</button>
                </div>
              </div>
              <div className="col-sm-8">
                <div className="flex_actions end">
                  <div className="action_buttons text-lg-end flex_actions end">
                    <button type="button" ref={saveRef} onClick={handleSubmit} disabled={freeze} className="btn">Save [E] &nbsp; {(loading) ? <i className="fa fa-circle-o-notch fa-spin"></i> : <span>&nbsp;</span>}</button>
                    <button type="button" ref={claimRef} className="btn" onClick={handleClaims}>Ticket [F2]</button>
                    <button type="button" className="btn" id="counter_btn" onClick={(e) => { e.preventDefault(); navigate('/counter'); }}>Counter</button>
                  </div>
                  <div className="flex_actions end">
                    <input type="text" className="form-control text-danger" placeholder="00" name="amount" value={receiptAmount} readOnly={true} />
                    <input type="text" className="form-control text-primary" placeholder="00" name="balance" value={afterBalance} readOnly={true} />
                    <button type="button" className="btn" id="counter_btn" onClick={logout} >Exit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Modal showModal={showModal} data={modalData} />
    </>
  )
};
