import React from 'react';
const Modal = ({showModal, data}) => {
  return (
    <>
    <div className={showModal?"overlay active" : "overlay"}></div>
    { data &&
      <div className={showModal?"modal fade show":'modal fade'} style={{display:(showModal?'block':'none')}} id="jackpotModal" data-bs-backdrop="static" data-bs-keyboard="false" aria-modal="true" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title text-center">You Won {data.jackpot && <i>{data.jackpot+ "Jackpot"}</i>}</h2>
            </div>
            <div className="modal-body">
              <div className="modal_content">
                <img src={"assets/theme/images/" + data.name + ".png"} alt={data.name} className="d-block img-fluid" />
                  <h3 className="text-center">{data.hindi}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
    </>
  );
};

export default Modal;
