import React from 'react'

function ToastObj() {
  return (
    {
      position: "top-right",
      autoClose: 5000, 
      hideProgressBar: false, 
      closeOnClick: true, 
      pauseOnHover: true, 
      draggable: true, 
      progress: undefined,
    }
  )
}

export default ToastObj