import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function Navigation(auth_user) {
  const navigate = useNavigate();
  return (
    <nav className="sticky-top custom_nav navbar navbar-expand-sm">
      <div className="container-fluid">
        <a className="navbar-brand" href=""><img src="assets/theme/images/galaxy-logo.png" className="img-fluid" /></a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="d-flex right_text">
          {/* <div><h4 style={{ cursor: "pointer" }} onClick={(e) => { e.preventDefault(); navigate('/customers') }}>{"Customers"}</h4></div>
          <div><h4 style={{ cursor: "pointer" }} onClick={(e) => { e.preventDefault(); navigate('/counter') }}>{"Counter"}</h4></div> */}
          {/* <div><h4 style={{ cursor: "pointer" }} onClick={(e) => { e.preventDefault(); navigate('/transactions') }}>{"Transactions"}</h4></div> */}
          <div><h4 style={{ fontSize: "20px", marginRight:"5px", cursor: "pointer" }} onClick={(e) => { e.preventDefault(); navigate('/five-game') }}><i className="fa fa-gamepad" /></h4></div>
          <div><h4 style={{ fontSize: "20px", marginRight:"5px", cursor: "pointer" }} onClick={(e) => { e.preventDefault(); navigate('/counter') }}><i className="fa fa-repeat" /></h4></div>
          {/* <div><h4 style={{ cursor: "pointer" }} onClick={(e) => { e.preventDefault(); navigate('/ten-game') }}>{"10 Min Game"}</h4></div> */}
          {<div><h4 style={{ fontSize: "20px", marginRight:"10px", cursor: "pointer" }} onClick={(e) => { e.preventDefault(); navigate('/transactions') }}><i className="fa fa-inr" /></h4></div>}
        </div>
      </div>
    </nav>
  )
}
