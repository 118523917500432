import React from 'react'
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'

export default function Sparkel() {
  const { width, height } = useWindowSize()
  return (
    <Confetti
      numberOfPieces={300}
      tweenDuration={2000}
      width={width}
      height={height}
      style={{ zIndex: 1070, position: 'absolute', top: 0, left: 0 }}
    />
  )
}
