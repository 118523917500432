import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
axios.defaults.withCredentials = true;

/* export default axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  rejectUnauthorized: true,
  timeout: 10000,
  followRedirect: true,
  maxRedirects: 10,
  headers: {
    'Cache-Control': 'no-cache, no-store, private',
    "Content-Type": "text/plain",
  }
}); */

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  rejectUnauthorized: true,
  timeout: 10000,
  followRedirect: true,
  maxRedirects: 10,
  headers: {
    'Cache-Control': 'no-cache, no-store, private',
    "Content-Type": "text/plain",
  }
});

axiosInstance.interceptors.request.use(
  config => {
    return config;
  },
  error => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if (error.response.status === 500 || (error.response.status === 401)) {
      if (!originalRequest._retry)
        originalRequest._retry = true;
      const accessToken = Cookies.get('_accessToken');
      const refreshToken = localStorage.getItem('token');
      if (!accessToken || !refreshToken) {
        localStorage.removeItem('token');
        localStorage.removeItem('auth');
        window.location.href = "/"
      }
      return axiosInstance(originalRequest);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;